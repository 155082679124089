<template>
    <div>

        <div class="hb-primary hb-design-system-border-radius px-4 white--text">
            <hb-header :divider="false">
                <hb-page-header title="Elevations"></hb-page-header>
            </hb-header>
        </div>

        <!--
        <hb-tabs v-model="activeTab">
            <v-tab
                :ripple="false"
            >
                Prototype
            </v-tab>
        </hb-tabs>

        <v-divider></v-divider>
        -->

        <div>

            <v-row no-gutters>
                <v-col cols="5" class="d-flex justify-center">

                    <div class="elevation-example-div mx-6 mb-6 mt-9" :class="elevation.name"></div>
                
                </v-col>

                <v-col cols="7" class="pt-3">
                    <hb-card title="CSS Class and Rule">

                        <hb-form label="Elevation CSS Class">
                            <HbSelect
                                v-model="elevation"
                                :items="classes"
                                item-text="name"
                                return-object
                                placeholder="Select Elevation"
                                :clearable="false"
                            />
                        </hb-form>

                        <hb-form full label="Elevation CSS Box Shadow Rule">{{ elevation.rule }}</hb-form>

                        <hb-form full label="Elevation Usages">{{ elevation.description }}</hb-form>
                    </hb-card>
                </v-col>
            </v-row>

            <v-row no-gutters :class="{ 'mt-3' : $vuetify.breakpoint.sm }">
                <v-col cols="12" no-gutters>
                    <hb-card color="#E0F5F5" title="Code" class="mt-3">
                        <pre class="px-4">

&lt;div class="{{ elevation.name }}">&lt;/div>
                        </pre>
                    </hb-card>
                </v-col>
            </v-row>

            <hb-card class="my-6" title="Elevations">
                <hb-data-table
                    :headers="headers"
                    :items="classes"
                    class="pa-6"
                >
                    <template v-slot:item.name="{ item }">
                        .{{ item.name }}
                    </template>
                </hb-data-table>
            </hb-card>

        </div>
        
    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemElevations",
        data: function() {
            return {
                activeTab: 0,
                codeExpanded: false,
                elevation: { name: 'hb-elevation-small', rule: 'box-shadow: 0px 2px 4px 2px rgba(71, 84, 116, 0.1) !important;', description: 'Modal Sticky Headers, other places TBD' },
                headers: [
                    { text: 'CSS Class Name', value: 'name' },
                    { text: 'Rule', value: 'rule' },
                    { text: 'Description', value: 'description' }
                ],
                classes: [ 
                    { name: 'hb-elevation-small', rule: 'box-shadow: 0px 2px 4px 2px rgba(71, 84, 116, 0.1) !important;', description: 'Modal Sticky Headers, other places TBD' },
                    { name: 'hb-elevation-medium', rule: 'box-shadow: 0px 4px 10px 3px rgba(71, 84, 116, 0.1) !important;', description: 'Accordions, Tenant Summary Cards' },
                    { name: 'hb-elevation-large', rule: 'box-shadow: 0px 8px 18px rgba(71, 84, 116, 0.2) !important;', description: 'Dropdown Menus, Tooltips, Success / Error Message Component, Mini-Charm' },
                    { name: 'hb-elevation-blade', rule: 'box-shadow: 8px 0px 18px rgba(71, 84, 116, 0.2) !important;', description: 'Standard full-height blade that slides out from the left side of the screen' },
                    { name: 'hb-elevation-bulk-edit-blade', rule: 'box-shadow: -8px 0px 18px rgba(71, 84, 116, 0.2) !important;', description: 'Bulk Edit blade menu that appears from the right side of the table' },
                    { name: 'hb-elevation-table-panel-left', rule: 'box-shadow: 4px 0px 10px rgba(71, 84, 116, 0.1) !important;', description: 'The left side table panel / column' },
                    { name: 'hb-elevation-table-panel-right', rule: 'box-shadow: -4px 0px 10px rgba(71, 84, 116, 0.1) !important;', description: 'The right side table panel / column' }
                ],
            };
        },
    }
</script>

<style scoped>

.elevation-example-div {
    height: 50%;
    width: 50%;
    padding: 25%;
    background: #fff;
}
</style>